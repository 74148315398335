import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Layout } from "../layout";
import emailjs from '@emailjs/browser';


function Contact(){

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_h3p8g8b', 'template_rgkdgbb', form.current, 'user_570corddDmE3et5nIDXFd')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          alert("Your message has been successfully sent. We will contact you soon for further details.")
          e.target.reset()
      };


    return(
        <Layout>
            <div className="contact-image-container"></div>
            <div className="">
                <div className="flex flex-col items-center text-blue-500 bg-white">
                    <div className="p-10 w-full flex flex-col items-center bg-blue-500 text-gray-50">
                        <h1 className="text-center text-5xl tracking-widest title-font">
                            Contact Us
                        </h1>
                        <div className="sm:w-3/5 text-center mt-10 text-xs">
                            <p className="tracking-wide leading-5">FOR GENERAL ENQUIRIES & FURTHER INFORMATION, <br />CONTACT US ON: <span><a href="tel:+977-984-988-1660">+977-9849881660</a>, <a href="tel:+977-984-928-0516">+977-9849280516</a></span> <br /><br /><p>TO FIND THE LOCATION OF OUR HOTEL CLICK <span className="location-link"><Link to="/location">HERE.</Link></span></p><p className="">ALTERNATIVELY YOU CAN ALSO USE THE FORM BELOW TO CONTACT US:</p></p>
                        </div>
                    </div>

                    <div className="w-full h-10"></div> 
                    
                    <div className="sm:w-6/12 w-full p-10 bg-gray-100 contact-container">
                        <form className="text-xs flex flex-col" onSubmit={sendEmail} ref={form}>
                            <div className="mb-2">
                                <h1 className="text-2xl">CONTACT FORM</h1>
                            </div>
                            <hr />
                            <div className="mt-8 mb-1">
                                <label>Full Name</label>
                                <input placeholder="Name" name="user_name" type="text" required className="w-full p-2 block mb-1 text-sm text-black"></input>
                            </div>
                            <div className="mb-1">
                                <label>Email</label>
                                <input placeholder="Email" name="user_email" type="email" required className="w-full p-2 block mb-1 text-sm text-black"></input>
                            </div>
                            <div className="mb-1">
                                <label>Phone Number</label>
                                <input placeholder="+1 (555) 1234-567" name="user_phone" type="tel" required className="w-full p-2 block mb-1 text-sm text-black"></input>
                            </div>
                            <div className="mb-1">
                                <label>Your Message</label>
                                <textarea placeholder="Type your message here..." name="user_message" rows="10" required className=" w-full block mb-2 text-sm text-black"></textarea>
                            </div>
                            <div>
                                <button type="submit" class="w-full mt-5 px-3 py-4 text-white bg-blue-500 rounded-md focus:bg-blue-600 focus:outline-none">Send Message</button>
                            </div>
                        </form>
                    </div>
                    <div className="w-full h-10"></div>
                </div>
            </div>

        </Layout>
    )
}
export default Contact 


// border-red-300 border-2
import React, { useState } from "react";
import { Layout } from "../layout";
import slide1 from "../img/aboutpage-slide1.jpg"
import slide2 from "../img/aboutpage-slide2.jpg"
import slide3 from "../img/aboutpage-slide3.jpg"
import slide4 from "../img/aboutpage-slide4.jpg"
import slide5 from "../img/aboutpage-slide5.jpg"
import slide6 from "../img/aboutpage-slide6.jpg"

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import "swiper/css/effect-fade"
import "swiper/css/thumbs"
import "swiper/css/free-mode"



import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore from "swiper"

import { Pagination, A11y, EffectFade, Autoplay } from "swiper"
import SwiperCore, { FreeMode,Navigation,Thumbs } from 'swiper';

SwiperCore.use([FreeMode,Navigation,Thumbs]);


function About(){

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return(
        <Layout>
            <div className="about-image-container"></div>
            <div>
                <div className="flex flex-col items-center main-content-bg">
                    <div className="p-10 w-full flex flex-col items-center bg-blue-500">
                        <h1 className="text-center text-5xl tracking-widest text-heading title-font">
                            About Us
                        </h1>
                        <div className="sm:w-6/12 text-center mt-8 text-xs text-heading">
                            <p className="tracking-wide leading-5 p-2"><span>New Himalayan Cottage</span> is a family run business, 
                                located at <b>Surki (karche) Manang, Nepal.</b> It is in a small valley surrounded by pine cone and rhododendron trees.
                            </p>
                            <br />
                            <p className="tracking-wide leading-5 p-2">
                            The hotel is open almost all year round except <span>december</span> and <span>january</span> due to heavy snowfall. 
                            </p>
                        </div>
                    </div>
                    <div className=" sm:flex sm:flex-row sm:justify-evenly mt-10 mb-10">
                        {/* Monitor viewpoint */}
                        <div className="hidden sm:block sm:mr-5">
                            <Swiper
                                modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
                                style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
                                spaceBetween={5}
                                slidesPerView={1}
                                navigation={true}
                                autoHeight={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                loop={true}
                                centeredSlides={true} 
                                effect={'fade'}
                                autoplay={{
                                    "delay": 2500,
                                    "disableOnInteraction": false
                                }}
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                className="photo-swiping-monitor mb-1"
                            >
                            <SwiperSlide><img src={slide1} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide2} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide3} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide4} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide5} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide6} alt="hotel" /></SwiperSlide>
                            </Swiper>
                            {/* <Swiper 
                                onSwiper={setThumbsSwiper} 
                                loop={true} 
                                spaceBetween={5} 
                                slidesPerView={4} 
                                freeMode={true} 
                                watchSlidesProgress={true} 
                                className="photo-swiping"
                            >
                            <SwiperSlide><img src={slide1} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide2} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide3} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide4} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide5} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide6} alt="hotel" /></SwiperSlide>
                            </Swiper> */}
                        </div>

                        {/* Phone viewpoint */}
                        <div className="sm:hidden">
                            <Swiper
                                modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
                                style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
                                spaceBetween={5}
                                slidesPerView={1}
                                navigation={true}
                                autoHeight={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                loop={true}
                                centeredSlides={true} 
                                effect={'fade'}
                                autoplay={{
                                    "delay": 2500,
                                    "disableOnInteraction": false
                                }}
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                                className="photo-swiping photo-swiping-phone mb-1"
                            >
                            <SwiperSlide><img src={slide1} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide2} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide3} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide4} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide5} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide6} alt="hotel" /></SwiperSlide>
                            </Swiper>
                            <Swiper 
                                onSwiper={setThumbsSwiper} 
                                loop={true} 
                                spaceBetween={5} 
                                slidesPerView={4} 
                                freeMode={true} 
                                watchSlidesProgress={true} 
                                className="photo-swiping photo-swiping-phone"
                            >
                            <SwiperSlide><img src={slide1} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide2} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide3} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide4} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide5} alt="hotel" /></SwiperSlide>
                            <SwiperSlide><img src={slide6} alt="hotel" /></SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="flex flex-col justify-center items-center background-card sm:ml-5 py-10 mt-10">
                            <h1 className="text-center text-2xl tracking-widest font-black title-font">
                                HOTEL AMENITIES
                            </h1>
                            <div className="w-20 border-b-2 border-gray-200 p-2"></div>
                            <ul className="text-left mt-8 text-xs font-grey">
                                <li className="p-1 tracking-wide">- Fresh organic food produced from our own garden.</li>
                                <li className="p-1 tracking-wide">- Serving breakfast, lunch & dinner.</li>
                                <li className="p-1 tracking-wide">- Single cottage rooms and attached rooms available.</li>
                                <li className="p-1 tracking-wide">- 24-hrs hot shower.</li>
                                <li className="p-1 tracking-wide">- Horse riding and mule transportation service.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default About 
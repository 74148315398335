// import react from "react";
import Nav from "./Nav";
import Footer from "./Footer";

function Layout(props){
    return(
        <div className="flex-1 background-grey">
            {props.children}
        </div>
    )
}


export { Layout, Nav, Footer } 
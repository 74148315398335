import React from "react";
import { Layout } from "../layout";


function Activities(){
    return(
        <Layout>
            <div className="about-image-container-top flex flex-col justify-center items-center bg-blue-500 font-white p-10 text-center">
                <h1 className="h-1/4 text-5xl text-heading mb-8">Activities</h1>
                <p className="text-xs tracking-wide leading-5">The main activities close by the hotel are the famous Manaslu trek, Annapurna Circuit trek as well as other treks.</p>
            </div>
            <div className="text-gray-500 flex flex-col mt-5 mb-5 h-auto w-full">
                <div className="flex flex-col items-center">
                    <div className="sm:flex sm:flex-row flex flex-col w-full sm:w-1/2 mt-5 mb-5 sm:mb-0">
                        <div className="grid-item sm:w-1/2 activities-container-one sm:mr-5 mb-5 bg-gray-50">
                            <div className="activities-container-one-top flex flex-col justify-center items-center text-xl text-white">
                                <p className="">Manaslu Trek</p>
                            </div>
                            <div className="flex flex-col justify-around items-center text-center text-sm activities-container-one-bottom p-2">
                                <p>The hotel resides in the Manaslu trekking route. It is located in surki (karche) between Dharapani and Bhimthang. </p>
                                <div className="w-14 border-b-2"></div>
                                <a className="bg-blue-500 text-white p-2 w-1/2 hover:bg-blue-600" href="https://www.kimkim.com/c/the-manaslu-circuit-trek"><button>VIEW DETAILS</button></a>
                            </div>
                        </div>
                        <div className="grid-item sm:w-1/2 activities-container-two sm:mb-5 bg-gray-50">
                            <div className="activities-container-two-top flex flex-col justify-center items-center text-xl text-white">
                                <p className="">Annapurna Circuit Trek</p>
                            </div>
                            <div className="flex flex-col justify-around items-center text-center text-sm activities-container-one-bottom p-2">
                                <p>After four hours down to Dharapani, one can start this trek.</p>
                                <div className="w-14 border-b-2"></div>
                                <a className="bg-blue-500 text-white p-2 w-1/2 hover:bg-blue-600" href="https://www.intrepidtravel.com/uk/nepal/annapurna-circuit-trek-126558?gclid=Cj0KCQiA_8OPBhDtARIsAKQu0gZtGMHG2PQoPGk4IkmwZpdkq1CCE84a9a72CaDN4cPoLrmrP7p7UewaAu6gEALw_wcB"><button>VIEW DETAILS</button></a>
                            </div>
                        </div>

                    </div>
                    <div className="sm:flex sm:flex-row flex flex-col w-full sm:w-1/2 mb-5 sm:mb-0">
                        <div className="grid-item sm:w-1/2 activities-container-three sm:mr-5 mb-5">
                            <div className="activities-container-three-top flex flex-col justify-center items-center text-xl text-white">
                                <p className="">Tilicho lake Trek</p>
                            </div>
                            <div className="flex flex-col justify-around items-center text-center text-sm activities-container-one-bottom p-2">
                                <p>After four hours down to Dharapani, one can start this trek. This trek follows the Annapurna Circuit Trek route.</p>
                                <div className="w-14 border-b-2"></div>
                                <a className="bg-blue-500 text-white p-2 w-1/2 hover:bg-blue-600" href="https://www.kimkim.com/c/himalayan-trek-through-the-scenic-tilicho-lake"><button >VIEW DETAILS</button></a>
                            </div>
                        </div>
                        <div className="grid-item sm:w-1/2 activities-container-four sm:mb-5 bg-gray-50">
                            <div className="activities-container-four-top flex flex-col justify-center items-center text-xl text-white">
                                <p className="">Dhaulagiri Circuit Trek</p>
                            </div>
                            <div className="flex flex-col justify-around items-center text-center text-sm activities-container-one-bottom p-2">
                                <p>After four hours down to Dharapani, one can start this trek. This is a demanding trek for those with previous high altitude trekking experience. </p>
                                <div className="w-14 border-b-2"></div>
                                <a className="bg-blue-500 text-white p-2 w-1/2 hover:bg-blue-600" href="https://www.satoriadventuresnepal.com/dhaulagiri-circuit-trekking.html"><button>VIEW DETAILS</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Activities
import React from "react";
import { Layout } from "../layout";
import roomOne from "../img/room-single.jpg"
import roomTwo from "../img/room-double.jpg"


function Rooms(){
    return(
        <Layout>
            <div className="about-image-container-top flex flex-col justify-center items-center bg-blue-500 font-white p-10">
                <h1 className="h-1/4 text-5xl text-heading mb-8 ">Rooms</h1>
                <p className="text-xs tracking-wide leading-5">There are 12 cottages with a total of 30 beds.</p><br />
                <p className="text-xs tracking-wide leading-5 text-center">The prices start from 500 nepali rupees per night for single cottage.</p>
            </div>
            <div className="text-gray-500 flex flex-col items-center justify-center w-screen">
                <div className="flex flex-col items-center w-4/5">
                    <h1 className="text-2xl mt-14 text-gray-600">Our Rooms</h1>
                    <div className="w-20 border-b-2 border-gray-200 p-2 mb-16"></div>
                        <div className="sm:flex sm:flex-row sm:justify-evenly w-3/4 mb-16">
                            <img src={roomOne} alt="room" width={240}/>
                            <div className="flex flex-col justify-evenly sm:px-5">
                                <h1 className="text-xl text-gray-600 py-5">Single Cottage</h1>
                                <p className="text-xs mb-5">Our single cottage offers a cozy double bed.</p>
                                <p className="text-xs mb-5">
                                    <ul className="list-disc ml-3">
                                        <li> Size: 50 sq ft</li>
                                        <li> Beds: 1 Double</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="sm:flex sm:flex-col sm:justify-center">
                                <h1 className="text-xs">From</h1>
                                <h1 className="text-xl">रु 500 (NPR)</h1>
                            </div>
                        </div>
                        <div className="w-2/3 border-b-2 border-gray-200 mb-16"></div>
                        <div className="sm:flex sm:flex-row sm:justify-evenly w-3/4 mb-16">
                            <img src={roomTwo} alt="room" width={240}/>
                            <div className="flex flex-col justify-evenly sm:px-5">
                                <h1 className="text-xl text-gray-600 py-5">Attached Cottage</h1>
                                <p className="text-xs mb-5">Our attached cottage offers two cozy single beds.</p>
                                <p className="text-xs mb-5">
                                    <ul className="list-disc ml-3">
                                        <li> Size: 50 sq ft</li>
                                        <li> Beds: 2 Double</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="sm:flex sm:flex-col sm:justify-center">
                                <h1 className="text-xs">From</h1>
                                <h1 className="text-xl">रु 800 (NPR)</h1>
                            </div>
                        </div>
                        <div className="w-2/3 border-b-2 border-gray-200 mb-16"></div>
                </div>
            </div>

        </Layout>
    )
}
export default Rooms 
import React from 'react';
import { Layout } from '../layout';
import { Link } from 'react-router-dom';
import slide1 from '../img/homepage-slide1.jpg';
import slide2 from '../img/homepage-slide2.jpg';
import slide3 from '../img/homepage-slide3.jpg';
import slide4 from '../img/homepage-slide4.jpg';
import slide5 from '../img/homepage-slide5.jpg';
import slide6 from '../img/homepage-slide6.jpg';
import slide7 from '../img/homepage-slide7.jpg';
import slide8 from '../img/homepage-slide8.jpg';
import slide9 from '../img/homepage-slide9.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';

// swiper bundle styles
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

// import SwiperCore from "swiper"

import { A11y, EffectFade, Autoplay } from 'swiper';
import SwiperCore, { Pagination, Navigation } from 'swiper';

SwiperCore.use([Pagination, Navigation]);

function Home() {
  return (
    <Layout>
      {/* Desktop screen */}
      <div className='hidden sm:block welcome-cards sm:flex sm:flex-col sm:justify-center sm:items-center sm:justify-evenly text-center'>
        <div className='flex flex-col justify-between'>
          <h1 className='text-lg text-xl'>Welcome to</h1>
          <h1 className='title-font text-5xl text-blue-50 homepage-text'>
            New Himalayan Cottage
          </h1>
        </div>
        <div className='welcome-cards-container flex flex-row justify-center'>
          <div className='welcome-cards-1 p-8 flex flex-col bg-white w-60 items-stretch text-center bg-gray-100'>
            <h1 className='text-xl text-blue-500'>Rooms and Rates</h1>
            <p className='text-xs text-gray-600 mt-10 mb-10'>
              There are 12 cottages with a total of 30 beds, meanwhile the
              prices start from 500 nepali rupees per night for single cottage.
            </p>
            <Link
              to='/rooms'
              className='text-xs text-white bg-blue-500 hover:bg-blue-600'
            >
              <button className='p-2'>More Info</button>
            </Link>
          </div>
          <div className='welcome-cards-1 p-8 flex flex-col bg-white w-60 items-stretch text-center bg-gray-100 ml-5 mr-5'>
            <h1 className='text-xl text-blue-500'>Location</h1>
            <p className='text-xs text-gray-600 mt-10 mb-10'>
              The hotel is located at <span>Surki (karche) Manang</span>. It is
              4 hrs walk up from Dharapani and 4 hrs walk down from Bhimthang.
            </p>
            <Link
              to='/location'
              className='text-xs text-white bg-blue-500 hover:bg-blue-600'
            >
              <button className='p-2'>More Info</button>
            </Link>
          </div>
          <div className='welcome-cards-1 p-8 flex flex-col bg-white w-60 items-stretch text-center bg-gray-100'>
            <h1 className='text-xl text-blue-500'>Activities</h1>
            <p className='text-xs text-gray-600 mt-10 mb-10'>
              The main activities close by the hotel are the famous Manaslu
              trek, Annapurna Circuit trek as well as other treks.{' '}
            </p>
            <Link
              to='/activities'
              className='text-xs text-white bg-blue-500 hover:bg-blue-600'
            >
              <button className='p-2'>More Info</button>
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile screen */}
      <div className='sm:hidden small-welcome-cards  flex flex-col justify-center text-center items-center'>
        <div className='flex flex-col justify-start items-center h-3/5'>
          <h1 className='text-md homepage-text'>Welcome to</h1>
          <h1 className='title-font text-4xl homepage-text'>
            New Himalayan Cottage
          </h1>
        </div>
      </div>

      <div className='sm:hidden welcome-cards-container flex flex-col justify-center items-center'>
        <div className='welcome-cards-1 p-8 flex flex-col bg-white w-full items-stretch text-center bg-gray-100 mt-5 mb-5'>
          <h1 className='text-xl text-blue-500'>Rooms and Rates</h1>
          <p className='text-xs text-gray-600 mt-10 mb-10'>
            There are 8 cottages with a total of 15 beds, meanwhile the prices
            start from 800 nepali rupees per night for 1 bed cottage.
          </p>
          <Link
            to='/rooms'
            className='text-xs text-white bg-blue-500 hover:bg-blue-600'
          >
            <button className='p-2'>More Info</button>
          </Link>
        </div>
        <div className='welcome-cards-1 p-8 flex flex-col bg-white w-full items-stretch text-center bg-gray-100 mb-5'>
          <h1 className='text-xl text-blue-500'>Location</h1>
          <p className='text-xs text-gray-600 mt-10 mb-10'>
            The hotel is located at <span>Surki (karche) Manang</span>. It is 4
            hrs walk up from Dharapani and 4 hrs walk down from Bhimthang.
          </p>
          <Link
            to='/location'
            className='text-xs text-white bg-blue-500 hover:bg-blue-600'
          >
            <button className='p-2'>More Info</button>
          </Link>
        </div>
        <div className='welcome-cards-1 p-8 flex flex-col bg-white w-full items-stretch text-center bg-gray-100 mb-5'>
          <h1 className='text-xl text-blue-500'>Activities</h1>
          <p className='text-xs text-gray-600 mt-10 mb-10'>
            The hotel is located at <span>Surki (karche) Manang</span>. It is 4
            hrs walk up from Dharapani and 4 hrs walk down from Bhimthang.
          </p>
          <Link
            to='/activities'
            className='text-xs text-white bg-blue-500 hover:bg-blue-600'
          >
            <button className='p-2'>More Info</button>
          </Link>
        </div>
      </div>

      <div className='text-blue-500 text-l home-separation flex flex-col items-center justify-center py-10'>
        <h1 className='text-gray-700 text-center'>
          Call one of our numbers to enquire about the hotel and book rooms.
        </h1>
        <p className='text-xs text-gray-600 text-center'>
          Additionally you can also make an enquiry using our contact form.
        </p>
        <div className='flex flex-row items-center sm:mt-5 mt-10'>
          <div className='mr-5 text-blue-500'>
            <a href='tel:+977-984-988-1660'>+977-9849881660</a>
            <br />
            <a href='tel:+977-984-928-0516'>+977-9849280516</a>
          </div>
          <div>
            <Link to='/contact' className=''>
              <button className='text-xs text-white bg-blue-500 p-3 ml-5 hover:bg-blue-600'>
                MAKE AN ENQUIRY
              </button>
            </Link>
          </div>
        </div>
      </div>
      <h1 className='text-gray-600 text-xl mt-5 text-center'>
        Photos of the New Himalayan Cottage
      </h1>
      {/* Desktop view */}
      <div className='hidden sm:block photo-slide text-gray-600 flex flex-row justify-center items-center mt-5 mb-10'>
        <Swiper
          modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          navigation={true}
          slidesPerView={3}
          slidesPerGroup={3}
          spaceBetween={5}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          loopFillGroupWithBlank={true}
          pagination={{ clickable: true }}
          className='mySwiper'
        >
          <SwiperSlide>
            <img loading='lazy' src={slide1} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide2} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide3} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide4} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide5} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide6} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide7} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide8} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide9} alt='hotel' />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Mobile view */}
      <div className='sm:hidden photo-slide text-gray-600 flex flex-row justify-center items-center mt-5 mb-10 '>
        <Swiper
          modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          navigation={true}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={5}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          loopFillGroupWithBlank={true}
          pagination={{ clickable: true }}
          className='mySwiper'
        >
          <SwiperSlide>
            <img loading='lazy' src={slide1} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide2} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide3} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide4} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide5} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide6} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide7} alt='hotel' />
          </SwiperSlide>
          <SwiperSlide>
            <img loading='lazy' src={slide8} alt='hotel' />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className='home-separation-two flex flex-col items-center justify-center py-10'>
        <h1 className='text-gray-700 text-2xl mb-5'>Nearby places</h1>
        <br />
        <div className='text-blue-400 text-sm flex flex-row justify-between'>
          <div className='sm:ml-5 ml-5 sm:text-left'>
            <h1>
              Bhimthang{' '}
              <span className='text-xs text-gray-600 p-2'>
                {' '}
                - LESS THAN 5 MILES AWAY
              </span>
            </h1>
            <br />
            <h1>
              Hampuk{' '}
              <span className='text-xs text-gray-600 p-2'>
                {' '}
                - LESS THAN 5 MILES AWAY
              </span>
            </h1>
          </div>
          <div className='sm:ml-5 ml-10 sm:text-left'>
            <h1>
              Gho
              <span className='text-xs text-gray-600 p-2'>
                {' '}
                - LESS THAN 5 MILES AWAY
              </span>
            </h1>
            <br />
            <h1>
              Tilije
              <span className='text-xs text-gray-600 p-2'>
                {' '}
                - LESS THAN 5 MILES AWAY
              </span>
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Home;

import { NavLink } from "react-router-dom";

function Nav(){
    return(
        <nav className="md:text-xs sm:text-xs navigation text-lg flex flex-row justify-evenly items-center">
            <ul className="navigation-inner flex flex-row sm:justify-center md:justify-center justify-center w-screen">
                <li className="md:p-5 sm:p-5 p-5">
                    <NavLink to="/" activeClassName="nav-link" >HOME</NavLink>
                </li>
                <li className="md:p-5 sm:p-5 p-5">
                    <NavLink to="/about" activeClassName="nav-link" >ABOUT</NavLink>    
                </li>
                <li className="md:p-5 sm:p-5 p-5">
                    <NavLink to="/contact" activeClassName="nav-link" >CONTACT</NavLink>
                </li>
            </ul>
        </nav>
    )
}
export default Nav 
import React from "react";
import { Layout } from "../layout";
import large from "../img/map-large.jpg"
import small from "../img/map-small.jpg"
import largePng from "../img/manaslu-large.jpg"


function Location(){
    return(
        <Layout>
            <div className="about-image-container-top flex flex-col justify-center items-center text-center bg-blue-500 font-white p-10">
                <h1 className="h-1/4 text-5xl text-heading mb-8">Location</h1>
                <p className="text-xs tracking-wide leading-5">The hotel sits at an altitude of 2,700 meters above sea level and is on the famous Manaslu trekking route.</p>
                <p className="text-xs tracking-wide leading-5">The hotel is located at Surki (karche) Manang. It is 4 hrs walk up from Dharapani and 4 hrs walk down from Bhimthang.</p><br />
                <p className="text-xs tracking-wide leading-5">Below is the Manaslu trekking map and where our hotel is located in the map.</p>
            </div>
            <div className="flex flex-row justify-center w-full">
                <div className="sm:flex sm:flex-row flex flex-col justify-center items-center w-4/5 mt-10 mb-10">
                    <div className="sm:w-1/2 flex flex-col justify-center items-center">
                        <h1 className="text-center text-gray-600">Zoomed in</h1>
                        <span className="text-center mb-5 text-xs text-gray-500">(location is pinned inside the red circle)</span>
                        <img src={small} width="300vw" alt="hotel" />
                    </div>
                    <div className="sm:w-1/2 flex flex-col justify-center items-center sm:mt-0 mt-14">
                        <h1 className="text-center text-gray-600">Zoomed out</h1>
                        <span className="text-center mb-5 text-xs text-gray-500">(click image for full size)</span>
                        <a href={largePng} target="_blank" rel="noreferrer">
                            <img src={large} width="300vw" alt="hotel" />
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Location
import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { Home, About, Contact, Location, Rooms, Activities } from "./pages"
import { Footer, Nav } from "./layout";
import ScrollToTop from "./ScrollToTop"


function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/location" element={<Location />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/activities" element={<Activities />} />
        </Routes>
        <Footer />
      </Router> 
    </div>
  );
}

export default App;
